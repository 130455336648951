.card{
    margin: 1rem;
}



.title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.titleName{
    font-size: 1.5rem;
    font-weight: 800;
}

.titleSub{
    display: flex;
}

.titleSubText{
    font-size: 1rem;
    margin: 0 0.5rem;
}

.titleInfo{
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
    font-size: 0.9rem;
}
.titleInfoIcon{
    color:gray;
margin-right: 0.5rem;
}

.restaurantsHeader{
    background-color: rgba(221, 221, 221, 0);
    padding: 1rem;
    margin: 1rem;
}

.radiusControlModal{
    position: absolute;
    top: 5rem;
    background-color: rgb(255, 255, 255);
    width: 85%;
  height: 20rem;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
padding: 1rem;
display: flex;
flex-direction: column;
gap: 1rem
}

.overlayContainer{
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    z-index: 1000;
}

.modalHeader{
    padding-bottom: 1rem;
    border-bottom: 1px solid rgb(211, 211, 211);
}

.modalContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
 
}

.radiusValue{
    font-size: 2rem;
    font-weight: 600;
   
}

.radiusUnit{
    font-size: 1rem;
    font-weight: 400;
    color: rgb(139, 139, 139);
    line-height: 1rem;
}

.radiusControlBtn{
    position: absolute;
    top: 3.5rem;
    right: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    background-color: rgb(255, 255, 255);
    border-radius: 0.6rem;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
    color: rgb(145, 145, 145);
  
}
.filterButton{
    padding: 0.4rem 1rem;
    border: 1px solid rgb(194, 194, 194);
    border-radius: 0.5rem;
    background-color: white;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
    color: rgb(131, 131, 131);
    font-weight: 600;
}

.restaurantListContainer{
    top: 100px;
    position: absolute;
    width: 100%;
}
.restaurantLoading{
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 150px);
}

@media (min-width: 481px){
    .radiusControlModal{width: 50%;

}}