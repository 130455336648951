.card{
    margin: 1rem;
    padding: 0;
    border-radius: 1rem;
}

.cardSingleNoGrid{
    background-color: rgb(240, 240, 240);
   width: 100%;
    margin: 0.3rem 0;
    height: 5rem;
    
   border-radius: 1rem;
   display: flex;
   align-items: center;
   justify-content: center;
}

.cardSingleGrid{
    background-color: rgb(240, 240, 240);
   width: 48%;
    margin: 0.3rem 0;
    height: 6rem;
    
   border-radius: 1rem;
   display: flex;
   align-items: center;
   justify-content: center;
}

.cardSingle a{
    text-decoration: none;
    color: black;
    
}

.allStoreContainer{
    display: flex;
    flex-direction: row;
    
    flex-wrap: wrap;
    padding: 0.7rem 1rem;
    gap: 0.5rem;
    justify-content: space-around;
}

.allStoreContainerxxx{
    display: flex;
    flex-direction: column;
    
    /* flex-wrap: wrap; */
    padding: 0.7rem 1rem;
}

.title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.cardNoLogin{
    margin: 1rem;
    /* padding: 1rem; */
    border-radius: 1rem;
    
}

.noLoginButtonGroup{
width: 100%;
display: flex;
}

.noLoginButton{
   width: 100%;


}

.noLoginButtonContainer{
    padding: 0.2rem;
    width: 50%;
}

.cardSingleText{
font-size: 1rem;
font-weight: 600;
}

.noLoginContent{
    margin: 1rem 0;
    background-color: rgb(248, 248, 248);
    display: flex;
    justify-content: center;
    border-radius: 1rem;
}

.noLoginContent > :nth-child(2){
    display: none;
}

.noLoginContent > :nth-child(3){
    display: none;
}

.buttonGroup{
    display: flex;
    gap: 0.5rem;
}

.iconButtonContainer{
    height: 2rem;
    width: 2rem;
    border: 1px solid rgb(214, 214, 214);
    background-color: rgba(0, 0, 0, 0);
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.iconButtonContainer:hover{
   background-color: rgb(219, 219, 219);
}

.aboutContent{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap:1rem;
}

.cardSingleTextLink{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


@media (min-width: 560px){
    .noLoginContent > :nth-child(2){
        display: block;
    }
  
  }

  
@media (min-width: 680px){
    .noLoginContent > :nth-child(3){
        display: block;
    }
  
  }