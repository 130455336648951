.reviewGraphicContainer{
    background-color: rgb(247, 247, 247);
    margin: 1rem;
    padding: 1rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: space-between;
    
}

.singleBarContainer{
    border: none;
    height: 0.4rem;
    position: relative;
    background-color: rgb(223, 223, 223);
    display: flex;
    width: 10rem;
    border-radius: 1rem;
   
}

.singleBarProgress{
    height: 0.4rem;
    
    background-color: rgb(143, 192, 143);
    position: absolute;
    border-radius: 1rem;
   
}

.reviewStarContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
     border-bottom: 1px solid var(--component-border-color); 
}
.reviewStarHeader{
    margin: 0.5rem;
    font-size: 1rem;
}

.reviewBarContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    justify-content: center;
    align-items: center;
}

.singleBar{
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.reviewStarText{
    font-size: 1.6rem;
    font-weight: 700 ;
}

@media (min-width: 481px){
    .reviewGraphicContainer{
        flex-direction: row;
    }

    .reviewStarContainer{width: 50%;
        border-right: 1px solid var(--component-border-color); 
        border-bottom: none;
    }
    .reviewBarContainer{width: 50%}
    
    
}