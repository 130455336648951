.addressContainer{
    position: fixed;
    top: 45px;
    
    background-color: rgb(255, 255, 255);
   width: 100%;
    padding: 1rem 0;
    z-index: 2;
    box-shadow: rgb(0 0 0 / 5%) 0px 4px 6px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px;
    max-width: 800px;
}
/* .addressContainer{
  position: absolute;
  top: 0;
  
  background-color: rgb(255, 255, 255);
 width: 100%;
  padding: 1rem 0;
} */

.addressGroup{
    display: flex;
    align-items: center;
    width: 60%;
gap:0.5rem;
padding: 0 1rem;
cursor: pointer;
}

.overlayContainer{
    position: fixed;
    top: 0;
    left:0;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100vw;
    height: 100%;
    z-index: 1000;
}

.modalContainer{
    position: absolute;
    top: 0.5rem;
    background-color: rgb(255, 255, 255);
    width: 85%;
  height: 90%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
padding: 1rem;
display: flex;
flex-direction: column;
gap: 1rem
}

.modalHeader{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.headerText{
    font-weight: 600;
}

.searchBar{
    display: flex;
    background-color: rgb(255, 255, 255);
    padding: 0.3rem 1rem;
    border-radius: 0.5rem;
    gap: 0.5rem;
    align-items: center;
    border: 1px solid rgb(228, 228, 228);
    margin-bottom: 1rem;
    justify-content: space-between;
    
  }


  .searchBar input{
    border: none;
    background-color: rgb(255, 255, 255);
    width: 85%;
    font-size: 1rem;
    font-weight: 500;
  }
  .searchButtonGroup{
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .clearQueryButton{
    width: 1.5rem;
    height: 1.5rem;
    background-color:var(--button-off-black);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
   
  }

  .searchBarIcon{
    font-size: 1.6rem;
    color: rgb(82, 82, 82);
  }
  .searchBarGroup{
    padding: 1rem 0;
    border-bottom: 1px solid var(--component-border-color);
  }

  .modalLineSpacer{
    border-top: 1px solid var(--component-border-color);
  }

  .modalButton{
    width: 100%;
    border: none;
    padding: 1rem;
    font-size: 1rem;
    border-radius: 0.5rem;
  }
  .modalButton:hover{
    background-color: rgb(215, 215, 215);
  }

  .modalButtonGroup{
    padding: 1rem 0;
  }
  .modalMapLeftBtn{
    position: absolute;
    background-color: rgba(0, 0, 0, 0.185);
    width: 2.5rem;
    height: 2.5rem;
    z-index: 1000;
    top: 0.5rem;
    left: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
  }

  .modalMapOverlay{
    
    position: absolute;
    background-color: rgba(0, 0, 0, 0);
    z-index: 1000;
    /* z-index: 1000; */
    padding: 0.3rem 0.6rem;
    border-radius: 1rem;
    color :white;
    margin: 8rem auto;
max-width: fit-content;

}
   

    .modalMapOverlayBackground{
        position: absolute;
        background-color: rgba(0, 0, 0, 0);
        z-index: 1000;
        
        
        color :white;
        top: 0;
        left: 0;
       
      }
  

  .modalMapContainer{
    position: relative;
  }

  .modalAddress{
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0 1rem;
  }

  .modalTextContainer{
    padding: 1rem 0;
  }
  .locationSaveButton{
    padding: 1rem;
    border: none;
    border-radius: 0.5rem;
    font-size: 1rem;
    width: 100%;
    margin: 2rem 0;
  }
  .locationSaveButton:hover{
    background-color: rgb(215, 215, 215);
  }
  .addressSingleGroup{
    border-bottom: 1px solid var(--component-border-color);
    padding: 1rem 0;
    display: flex;
    align-items: center;
    /* gap: 1rem; */
    justify-content: space-between;
  }

  .addressSingleLeft{
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .addressSingleRight{
   padding: 0 1rem;
  }

  /* .modalAddressGroup{
    width: 100%;
  } */

  .optionGroup{
    padding: 0 1rem;
    display: flex;
    gap: 0.5rem;
    overflow-x: auto;
    scroll-behavior: smooth;

position: relative;
  }

 /* .optionGroup::after {
    content: "";
    position: absolute;
    top: 0;
    right: -1rem;
    bottom: 0;
    width: 10px;
    background: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1));
    pointer-events: none;
  } */

  .optionGroup::-webkit-scrollbar {
    display: none;
  }

  .optionSingle{
    border: 1px solid var(--component-border-color);
    padding: 0.2rem 1rem;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: 0.5rem
  }
  .optionSingleColor{
    background-color: rgb(121, 207, 157);
    border: 1px solid rgb(121, 207, 157);
    padding: 0.2rem 1rem;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
    font-weight: 600;
    white-space: nowrap;

  }

  .optionSingle:hover{
    background-color: rgb(232, 232, 232);
  }

  .topSectionGroup{
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.7rem;
  }

  .viewSelectorGroup{
    margin-right: 1rem;
    
    display: flex;
    border-radius: 0.5rem;
    border: 1px solid var(--component-border-color);
    cursor: pointer;
    max-width: 40%;
  }
  .addressTextLogo{
    display: flex;

  justify-content: center;
  align-items: center;
  }
  .addressText{
    white-space: nowrap;
    overflow: hidden;
  text-overflow: ellipsis;
  width: 190px;
  }
  .addressTextDummy{
    white-space: nowrap;
    overflow: hidden;
  text-overflow: ellipsis;
  width: 190px;
  height: 1.4rem;
  background-color: rgb(241, 241, 241);
  border-radius: 0.3rem;
  animation: blink 1s linear infinite;
  }

  
@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
}

  .viewSelector{
    padding: 0.3rem;
    width: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(214, 214, 214);
    
  }

  .verticalBorder{
    border-left: 1px solid var(--component-border-color)
  }

  @media (min-width: 481px){
    .modalContainer{max-width: 800px;

}
}