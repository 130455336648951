.inputMenuContainer{
    margin: 1rem 0;
    /* width: 100%; */
border: solid 1px; 
border-color: rgb(211, 211, 211);
border-radius: 10px;
padding: 1rem 1rem;
background-color: white;
box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

}

.textInput{
    border: none;
    width: 100%;
    font-size: 1rem;
    height: 2rem;
    
    background-color: white;
    }

    .buttonsGroup{
        display: flex;
        justify-content: flex-end;
      }

      .inputButton {
        background-color: #51d27ec0;
        border: 0;
        border-radius: .5rem;
        box-sizing: border-box;
        color: #ffffff;
        font-family: "Inter var",ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        font-size: .875rem;
        font-weight: 600;
        line-height: 1.25rem;
        padding: 0.4rem 1rem;
        text-align: center;
        /* text-decoration: none #D1D5DB solid;
        text-decoration-thickness: auto; */
        /* box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); */
        cursor: pointer;
        user-select: none;
        margin-right: 0.7rem;
        height: 2.2rem;
      }
      .inputButton:nth-of-type(2){
        background-color: #ccc;
      }

      .inputButton:hover {
        background-color: #4da46bc0;
        /* transform: translateY(-2px); */
      }

      .inputButton:nth-of-type(2):hover {
        background-color: #949494c0;
        /* transform: translateY(-2px); */
      }
      
      .inputButton:active {
          background-color: #4da46bc0;
          transform: translateY(-2px);
        }
      
      .inputButton[disabled=disabled], .inputButton:disabled {
          opacity: 0.5;
          
          /* pointer-events: none; */
          cursor: not-allowed;
      }

      .containerBottom{
        display: flex;
        justify-content: space-between;
      }

      .imageUploadGroup{
        display: flex;
        flex-wrap: wrap;
      }

      input[type="file"] {
        display: none;
      }
      
      /* input[type="submit"] {
        display: none;
      } */

      .fileInput{
        border: 1px solid #ccc;
        border-radius: 0.5rem;
        display: inline-block;
       height: 60px;
       width:60px;
        cursor: pointer;
        margin-right: 0.5rem;
        margin-top: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
        color: rgb(202, 202, 202);
      }

      .fileInput:hover{
        background-color: rgb(248, 248, 248);
      }

      .selectedImage{
        object-fit: cover;
        border-radius: 0.5rem;
        width: 60px;
        height: 60px;
        margin-right: 0.5rem;
        margin-top: 0.5rem;
    }

    .cancelButton {
        background-color: #e9e9e9c0;
        border: 0;
        border-radius: .5rem;
        box-sizing: border-box;
        color: #000000;
        font-family: "Inter var",ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        font-size: .875rem;
        font-weight: 600;
        line-height: 1.25rem;
        padding: 0.4rem 1rem;
        text-align: center;
        /* text-decoration: none #D1D5DB solid;
        text-decoration-thickness: auto; */
        /* box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); */
        cursor: pointer;
        user-select: none;
        /* -webkit-user-select: none;
        touch-action: manipulation; */
        height: 2.2rem;
      }
      
      .cancelButton:hover {
        background-color: #d6d6d6c0;
        transform: translateY(-2px);
      }
      
      .buttonsGroup{
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
      }

      .selectedImageContainer{
        position: relative;
      }

      .selectedImageButton{
        position: absolute;
        border-radius: 1rem;
        border: none;
        right: 0;
        width: 1.5rem;
        height: 1.5rem;
        background-color: rgb(182, 235, 217);
      }

      .reviewTitleGroup{
        font-size: 1.2rem;
        font-weight: 700;
margin-bottom: 0.3rem;
display: flex;
align-items: center;
      }

      .reviewTitleGroupIcon{
        font-size: 1rem;
        
        margin-right: 0.5rem;
        color: rgb(255, 255, 255);
        background-color: #51d27ec0;
        padding: 0.3rem;
        border-radius: 1rem;
      }