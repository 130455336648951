.card{
    margin: 1rem;
    /* margin-bottom: 0; */
    padding: 0
}

.title{
    padding: 0.8rem 1rem;
    font-weight: 600;
}
.addItemButton{
    padding: 0.8rem 1rem;
    display: flex;
    align-items: center;
/* justify-content: center; */
cursor: pointer;
}

.addItemButton:hover{
  background-color: #d4d4d4;
  font-weight: 600;
}

.addItemIcon{
    margin-right: 1rem;
    font-size: 1.5rem;
}

.singleItem{
    padding: 0.7rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.singleItemDragging{
  padding: 0.7rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fcfcfc;
  border: solid 1px; 
border-color: rgb(211, 211, 211);

box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  /* transform: rotate(3deg); */
}

.inputMenuContainer{
    margin: 0 1rem;
    /* width: 100%; */
border: solid 1px; 
border-color: rgb(211, 211, 211);
border-radius: 10px;
padding: 1rem 1rem;
background-color: white;
box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.inputMenuContainerBottom{
  display: flex;
  justify-content: space-between;
 
  height: 2.5rem;
  position: relative;
}

.menuSearchResult{
  background-color: #eeeeee;
  padding: 0.5rem;
  border-radius: 0.5rem;
  overflow: visible;
  z-index: 1000;
  height: fit-content;
}

.searchResultItem{
  padding: 0.3rem 0;
}

.textInput{
border: none;
width: 100%;
font-size: 1rem;
height: 2rem;

background-color: white;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.button-37 {
  background-color: #13aa52;
  border: 1px solid #13aa52;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .1) 0 2px 4px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: "Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  outline: 0;
  padding: 10px 25px;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-37:hover {
  box-shadow: rgba(0, 0, 0, .15) 0 3px 9px 0;
  transform: translateY(-2px);
}



.inputButton {
  background-color: #51d27ec0;
  border: 0;
  border-radius: .5rem;
  box-sizing: border-box;
  color: #ffffff;
  font-family: "Inter var",ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.25rem;
  padding: 0.4rem 1rem;
  text-align: center;
  /* text-decoration: none #D1D5DB solid;
  text-decoration-thickness: auto; */
  /* box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); */
  cursor: pointer;
  user-select: none;
  margin-right: 0.7rem;
}

.inputButton:hover {
  background-color: #4da46bc0;
  /* transform: translateY(-2px); */
}

.inputButton:active {
    background-color: #4da46bc0;
    transform: translateY(-2px);
  }

.inputButton[disabled=disabled], .inputButton:disabled {
    opacity: 0.5;
    
    /* pointer-events: none; */
    cursor: not-allowed;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 0.3; /* Firefox */
  }

  .menuSuggestionContainer{
    background-color: white;
    margin: 1rem;
    padding: 1rem;
    border-radius: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .menuSuggestionItem{
    padding: 0.3rem 1rem;
    margin: 0.5rem 0.5rem;
    background-color: #72d39a;
border-radius: 1rem;
color: white;
border: none;
position: relative;
  }

  .menuSuggestionDelete{
    position: absolute;
    background-color: #d4d4d4;
    border-radius: 1rem;
    width: 1.3rem;
    height: 1.3rem;
    right: -10px;
    top: -5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cancelButton {
    background-color: #e9e9e9c0;
    border: 0;
    border-radius: .5rem;
    box-sizing: border-box;
    color: #000000;
    font-family: "Inter var",ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: .875rem;
    font-weight: 600;
    line-height: 1.25rem;
    padding: 0.4rem 1rem;
    text-align: center;
    /* text-decoration: none #D1D5DB solid;
    text-decoration-thickness: auto; */
    /* box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); */
    cursor: pointer;
    user-select: none;
    /* -webkit-user-select: none;
    touch-action: manipulation; */
  }
  
  .cancelButton:hover {
    background-color: #d6d6d6c0;
    transform: translateY(-2px);
  }
  
  .buttonsGroup{
    display: flex;
    position: absolute;
    right: 0;
  }

.submitButtonContainer{
    padding: 0 1rem;
    
    /* width: 100%; */
    /* max-width: 500px;
    margin: 0 auto; */
    --buttonWidth: 100%;
}

.submitButtonGroup{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

 .submitButton{
    background-color: #51d27ec0;
    border: 0;
    border-radius: .5rem;
    box-sizing: border-box;
    color: #ffffff;
    font-family: "Inter var",ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.25rem;
    
    text-align: center;
    
    cursor: pointer;
    user-select: none;
    width: var(--buttonWidth);
    height: 2.5rem;
    margin-bottom: 0.7rem;
   
 }

 .backButton{
  background-color: #d6d6d6c0;
  border: 0;
  border-radius: .5rem;
  box-sizing: border-box;
  color: #ffffff;
  font-family: "Inter var",ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.25rem;
  
  text-align: center;
  
  cursor: pointer;
  user-select: none;
  width: var(--buttonWidth);
  height: 2.5rem;
 margin-bottom: 0.7rem;
}

 .singleItemButtons{
  display: flex;
  
 }

 .itemButton{
  width: 1.6rem;
  height: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
 
  margin: 0 0.2rem;
 }

 .itemButton:hover{
  background-color: #ECECEC;
 }

 .modalContent{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modalTitle{
font-size: 1rem;
font-weight: 600;
margin-bottom: 1rem;
}

.modalButton{
  width: 100%;
}

.modalContent{
margin-bottom: 1rem;
}



@media (min-width: 481px){
  .submitButtonContainer{
   
    --buttonWidth: calc(50% - 0.3rem);
}

}