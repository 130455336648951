:root{

  --component-border-color: rgb(219, 219, 219);
  --button-off-black: rgb(61, 61, 61);
  --button-green-theme: #51d27ec0;
  --button-grey-theme: #d6d6d6c0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

button{
  cursor: pointer;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.pincontainer {
  background-color: aquamarine;
  color: red;
}

/* .wrap{
  background-color: red;
} */
.wrap {
  position: absolute;
  left: 0;
  bottom: 40px;
  width: 288px;
  height: 132px;
  margin-left: -144px;
  text-align: left;
  overflow: hidden;
  font-size: 12px;
  font-family: 'Malgun Gothic', dotum, '돋움', sans-serif;
  line-height: 1.5;
  background-color: red
}

.close{position: absolute;top: 10px;right: 10px;color: #888;width: 17px;height: 17px;background: url('https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/overlay_close.png');}

.close:hover {cursor: pointer;}

/* .appshell{
  padding: 0;
  margin: 0;
  padding-top: 70px;
 background-color: rgb(248, 248, 248);
 width: 100vw;
} */

.appBackground{
  background-color: rgb(248, 248, 248);
height: calc(100vh - 45px);
}

.navbar{
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  max-width: 996px;
  margin: 0 auto;
}

.navbarside{
  /* width: 50%; */
  
}

.header{
  
  background-color: #ECECEC;
  
    position: fixed;
    top: 0;
    width: 100%;
    height: 45px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    z-index: 1000;
  }

.footer{
  height: 50px;
  background-color: #ECECEC;
  display: flex;
  justify-content: space-around;
  position: fixed;
  bottom: 0;
  color: red;
  padding: 0.2rem 0;
}

.footer a{
  text-decoration: none;
}

.container{
  /* background-color: rgb(248, 248, 248); */
  /* height: calc(100vh - 150px); */
  /* width: 100%; */
  margin-top: 45px;
  margin-bottom: 50px;
  min-height: 100vh;
  /* display: block;
overflow: auto; */
padding: 0.3rem 0;
max-width: 800px;
margin-left:  auto;
margin-right:  auto;

position: relative;
}

.container2{
  /* background-color: rgb(248, 248, 248); */
  /* height: calc(100vh - 150px); */
  /* width: 100%; */
  margin-top: 45px;
  margin-bottom: 50px;
  height: calc( 100vh - 90px);
  /* display: block;
overflow: auto; */
/* padding: 0.3rem 0; */
max-width: 800px;
margin-left:  auto;
margin-right:  auto;

position: relative;
}

.infoContainer{
  position: absolute;
  bottom: 20px;
  background-color: rgb(206, 206, 206);
  width: 95%;
height: 5rem;
margin-left: auto;
margin-right: auto;
left: 0;
right: 0;
text-align: center;
border-radius: 0.5rem;

}

.mapPage{
  position: relative;
  height: calc(100vh - 45px);
}

.kakaoMap{
  top: 100px;
  width: 100%;
  height: calc(100vh - 145px);
  /* height: calc(100vh - 85px); */
  
}

.kakaoMapSmall{
  top: 100px;
  width: 100%;
  height: calc(100vh - 145px - 18rem);
  /* height: calc(100vh - 85px); */
  
}

/* .mapLoadContainer{
  background-color: rgb(204, 204, 204);
  position: absolute;
  top:100px;
  width: 100%;
  height: calc(100vh - 145px);
  z-index: 0;
  animation: blink 1s linear infinite;
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
} */
.mapLoadContainer{
  background-color: white;
  position: absolute;
  top:100px;
  width: 100%;
  height: calc(100vh - 145px);
  z-index: 0;
display:flex;
align-items: center;
justify-content: center;
}

/* .kakaoMap{
  width: 100%;
  height: calc(100vh - 45px);
  
} */

@media(min-width: 1px){
  .footer{
    display: none;
  }
}

@media(max-width: 768px){
 .container{
  height: fit-content;
 }
}

.footerIcon{
  padding: 0;
  margin-bottom: -0.5rem;
}
.footerButton{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}

.footerButton:focus{
  background-color: red;
}
.footerButton:active{
  background-color: red;
}
a {
  color: black
}

/* start */

.box{
 display: flex;
 flex-direction: column;
 align-items: center;
}
.overlayContainer{
  background-color: white;
  padding: 0.7rem;
  border-radius: 1rem;
  width: 15rem;
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  white-space: normal;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.boxTail {
  width: 0;
	height: 0;
	border-left: 15px solid transparent;
	border-right: 15px solid transparent;
	border-top: 15px solid rgb(255, 255, 255);
}

.overlayHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.2rem;
}
.overlayHeader2{
  display: flex;

  padding-bottom: 0.3rem;
  margin-bottom: 0.3rem;
}

.overlayBody{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  padding-bottom: 0.5rem;
}

.overlayAdd{
  font-size: 0.8rem;
  display: flex;
  flex-wrap: wrap;
  color: rgb(163, 163, 163);
}

.overlayPhone{
  font-size: 0.8rem;
  padding: 0.2rem 0;
  color: rgb(126, 180, 140);
}

.overlayTitle{
  font-size: 1rem;
  font-weight: 600;
}

#map{
  z-index: 0;
}

.closeBtn{
  background: none;
  border: none;
  color: white;
  background-image: url("../public/cancel.png");
  background-size: contain;
  
  height: 1rem;
  width: 1rem;
}

.reviewValStar{
  background: none;
  
  color: white;
  background-image: url("./assets/star.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 1rem;
  width: 1rem;
  padding-right: 0.7rem;
  border-right: 1px solid rgb(204, 204, 204);
  
}

.link{
  border: none;
  
border-radius: 0.5rem;
padding: 0.5rem 0;
display: flex;
align-items: center;
}

.link a{
  display: block;
  text-decoration: none;
  width: 100%;
  font-size: 0.8rem;
  
}

.reviewVal{
  font-size: 0.8rem;
  font-weight: 400;
  padding-right: 0.2rem;
  
  
}

.reviewQty{
  padding-left: 0.7rem;
  font-size: 0.8rem;
  font-weight: 400;
}

.mapZoomButtons{
  position: absolute;
  top: 7rem;
  right: 0.5rem;
  background-color: rgb(255, 255, 255);
  border-radius: 0.6rem;
  color: rgb(145, 145, 145);
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  
}
/* .mapZoomButtons:hover{
  background-color: red;
} */

.zoomButtonIn{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-bottom: 1px solid rgb(197, 197, 197);
  transition: 0.3s;
  border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
}

.zoomButtonIn:hover{

  background-color: #eeeeee;
  
  border-top-left-radius: 0.6rem ;
  border-top-right-radius: 0.6rem ;
}

.zoomButtonOut{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  transition: 0.3s;
  border-bottom-left-radius: 0.6rem ;
  border-bottom-right-radius: 0.6rem ;
}

.zoomButtonOut:hover{

  background-color: #eeeeee;
  
  border-bottom-left-radius: 0.6rem ;
  border-bottom-right-radius: 0.6rem ;
}

.centerMap{
  position: absolute;
  top: 13rem;
  right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  background-color: rgb(255, 255, 255);
  border-radius: 0.6rem;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  color: rgb(145, 145, 145);
  transition: 0.3s;
}

.centerMap:hover{

  background-color: #eeeeee;
  
  border-bottom-left-radius: 0.6rem ;
  border-bottom-right-radius: 0.6rem ;
}


.geolocationMap{
  position: absolute;
  top: 16rem;
  right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  background-color: rgb(255, 255, 255);
  border-radius: 0.6rem;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  color: rgb(233, 84, 84);
  transition: 0.3s;
}
.geolocationMap:hover{

  background-color: #eeeeee;
  
  border-bottom-left-radius: 0.6rem ;
  border-bottom-right-radius: 0.6rem ;
}
